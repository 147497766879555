import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { translate } from "../../translations/localization";
import "./mstyles.scss";
import { useState } from "react";

const home1products = [
  {
    name: translate("homepageTreatmentPlant"),
    img: "/home/prechistvatelna-stancia.png",
    description: translate("homepageTreatmentPlantDescription"),
    link: "/product/65afc235e59a501dc54ccd22",
    pdf: false,
  },
  {
    name: translate("homepageHydrotank"),
    img: "/home/hydrotank.png",
    description: translate("homepageHydrotankDescription"),
    link: "/product/65b35703fe0b3bcf4ce31314",
    pdf: false,
  },
  {
    name: translate("homepagePumpingStation"),
    img: "/home/pompena-stancia.png",
    description: translate("homepagePumpingStationDescription"),
    link: "",
    pdf: false,
  },
  {
    name: translate("homepageCascadeShaft"),
    img: "/home/kaskadna.png",
    description: translate("homepageCascadeShaftDescription"),
    link: "/product/65b381c6fe0b3bcf4ce31432",
  },
  {
    name: translate("homepageHydroproLine"),
    img: "/home/hydroline.png",
    description: translate("homepageHydroproLineDescription"),
    link: "/product/65b3a741a8924c1efe1c0809",
    pdf: false,
  },
  {
    name: translate("homepageMaxi150"),
    img: "/home/61540000-maxi-150-d400-r00045.png",
    description: translate("homepageMaxi150Description"),
    pdf: true,
  },
  {
    name: translate("homepageTreeProtectionSystem"),
    img: "/home/treeprotectors.png",
    description: translate("homepageTreeProtectionSystemDescription"),
  },
  {
    name: translate("homepageDsrsRevision"),
    img: "/home/dsrs-s-cap.png",
    description: translate("homepageDsrsRevisionDescription"),
    link: "/product/65b39b9da8924c1efe1c07c4",
    pdf: false,
  },
  {
    name: translate("homepageHydrooilBypass"),
    img: "/home/hydrooil-bypass.png",
    description: translate("homepageHydrooilBypassDescription"),
    link: "/product/65b0b7f3e59a501dc54ccdb1",
    pdf: false,
  },
  {
    name: translate("homepageHydrotank"),
    img: "/home/hydrotank.png",
    description: translate("homepageHydrotankDescription"),
    link: "/product/65b35703fe0b3bcf4ce31314",
    pdf: false,
  },
];

const home2products = [
  {
    name: translate("secondHome.point1.title"),
    img: "/home2/1.png",
    description: translate("secondHome.point1.text"),
    //   link: "/product/65afc235e59a501dc54ccd22",
    pdf: false,
  },
  {
    name: translate("secondHome.point2.title"),
    img: "/home2/2.png",
    pdf: false,
  },
  {
    name: translate("secondHome.point3.title"),
    img: "/home2/3.png",
    pdf: false,
  },
  {
    name: translate("secondHome.point4.title"),
    img: "/home2/4.png",
    pdf: false,
  },
  {
    name: translate("secondHome.point5.title"),
    img: "/home2/5.png",
    pdf: false,
  },
  {
    name: translate("secondHome.point6.title"),
    img: "/home2/6.png",
    pdf: false,
  },
  {
    name: translate("secondHome.point7.title"),
    img: "/home2/7.png",
    description: translate("secondHome.point7.text"),
    pdf: false,
  },
  {
    name: translate("secondHome.point8.title"),
    img: "/home2/8.png",
    description: translate("secondHome.point8.text"),
    pdf: false,
  },
  {
    name: translate("secondHome.point9.title"),
    img: "/home2/9.png",
    pdf: false,
  },
  {
    name: translate("secondHome.point10.title"),
    img: "/home2/10.png",
    pdf: false,
  },
  {
    name: translate("secondHome.point11.title"),
    img: "/home2/11.png",
    pdf: false,
  },
  {
    name: translate("secondHome.point12.title"),
    img: "/home2/12.png",
    pdf: false,
  },
];

const HomeMobile = () => {
  const [products, setProducts] = useState(home1products);
  const [position, setPosition] = useState(0);

  const moveSlider = (direction) => {
    setPosition((prev) => Math.max(0, Math.min(1, prev + direction * 0.5)));
    setProducts(direction === 1 ? home2products : home1products);
  };

  const homeProducts = products.map((product, index) => {
    const isLast = products.length - 1 === index;
    return (
      <div key={index}>
       
          <Link to={product.link} className="item-wrap">
            <div className="item" id={`item-${index}`} style={{ borderBottom: isLast && "1px solid #1fecff", borderBottomLeftRadius: isLast && "7px", borderBottomRightRadius: isLast && "7px", paddingBottom: isLast && "0px" }}>
              <div className="box-info" style={{ borderBottom: isLast && "none" }}>
                <img src={product.img} alt={product.name} />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <h1 style={{ color: product.link && "#1FEDFF" }}>{product.name}</h1>
                  {product.link && <div className="navigate-arrow" />}
                </div>
                <p style={{ marginTop: "10px" }} dangerouslySetInnerHTML={{ __html: product.description }}></p>
              </div>
            </div>
          </Link>
      </div>
    );
  });

  const structuredJSON = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "HydroPro Ltd",
    image: "https://hydropro.bg/HydroPro-color.png",
    "@id": "",
    url: "https://hydropro.bg/",
    telephone: "+359 2/99 280 16",
    address: {
      "@type": "PostalAddress",
      streetAddress: "bul. Tsarigradsko shose 164",
      addressLocality: "Sofia",
      postalCode: "1138",
      addressCountry: "BG",
    },
  };

  return (
    <div className={`home-m ${position === 0 ? "first" : "second"}`}>
      <script className="structured-data-list" type="application/ld+json">
        {JSON.stringify(structuredJSON)}
      </script>

      <div className="container-m">
        <div className="products-list-m">
          <div className="scrollbar-screen-mobile-container">
            {position > 0 && <div className="arrow" onClick={() => moveSlider(-1)}></div>}
            <div className="scrollbar-line">
              <div className="scrollbar-slider" id="slider" style={{ left: `${position * 100}%` }}></div>
            </div>

            {position === 0 && <div className="arrow next" onClick={() => moveSlider(1)}></div>}
          </div>
          <div className={`wrapper ${position === 0 ? "first" : "second"}`}>{homeProducts}</div>
          <div className="scrollbar-screen-mobile-container">
            {position > 0 && <div className="arrow" onClick={() => moveSlider(-1)}></div>}
            <div className="scrollbar-line">
              <div className="scrollbar-slider" id="slider" style={{ left: `${position * 100}%` }}></div>
            </div>

            {position === 0 && <div className="arrow next" onClick={() => moveSlider(1)}></div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect()(HomeMobile);
