import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { translate } from "../../../translations/localization";
import "../styles.scss";

export const FirstHome = ({ setIsOpened }) => {
  const [productsList] = useState([
    {
      name: translate("homepageTreatmentPlant"),
      img: "/home/prechistvatelna-stancia.webp",
      description: translate("homepageTreatmentPlantDescription"),
      link: "/product/65afc235e59a501dc54ccd22",
      pdf: false,
    },
    {
      name: translate("homepageHydrotank"),
      img: "/home/hydrotank.webp",
      description: translate("homepageHydrotankDescription"),
      link: "/product/65b35703fe0b3bcf4ce31314",
      pdf: false,
    },
    {
      name: translate("homepagePumpingStation"),
      img: "/home/pompena-stancia.webp",
      description: translate("homepagePumpingStationDescription"),
      link: "",
      pdf: false,
    },
    {},
    {
      name: translate("homepageCascadeShaft"),
      img: "/home/kaskadna.webp",
      description: translate("homepageCascadeShaftDescription"),
      link: "/product/65b381c6fe0b3bcf4ce31432",
    },
    {
      name: translate("homepageHydroproLine"),
      img: "/home/hydroline.webp",
      description: translate("homepageHydroproLineDescription"),
      link: "/product/65b3a741a8924c1efe1c0809",
      pdf: false,
    },
    {
      name: translate("homepageMaxi150"),
      img: "/home/61540000-maxi-150-d400-r00045.webp",
      description: translate("homepageMaxi150Description"),
      pdf: true,
    },
    {
      name: translate("homepageTreeProtectionSystem"),
      img: "/home/treeprotectors.webp",
      description: translate("homepageTreeProtectionSystemDescription"),
    },
    {
      name: translate("homepageDsrsRevision"),
      img: "/home/dsrs-s-cap.webp",
      description: translate("homepageDsrsRevisionDescription"),
      link: "/product/65b39b9da8924c1efe1c07c4",
      pdf: false,
    },
    {
      name: translate("homepageHydrooilBypass"),
      img: "/home/hydrooil-bypass.webp",
      description: translate("homepageHydrooilBypassDescription"),
      link: "/product/65b0b7f3e59a501dc54ccdb1",
      pdf: false,
    },
    {},
    {
      name: translate("homepageHydrotank"),
      img: "/home/hydrotank.webp",
      description: translate("homepageHydrotankDescription"),
      link: "/product/65b35703fe0b3bcf4ce31314",
      pdf: false,
    },
  ]);
  const [prevSelectedId, setPrevSelectedId] = useState("");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".box-info")) {
        if (prevSelectedId !== "") {
          document.getElementById(`box-dashed-${prevSelectedId}`).className = "box-lines";
          document.getElementById(`box-info-${prevSelectedId}`).className = "box-info";
          setIsOpened(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [prevSelectedId]);

  const productsToggle = (id) => {
    let boxPoint = document.getElementById("box-point-" + id);
    let boxInfo = document.getElementById("box-info-" + id);
    let boxDashed = document.getElementById("box-dashed-" + id);

    boxInfo.className = "box-info box-info-active";
    boxDashed.className = "box-dashed-active";

    let screenHeight = document.body.clientHeight;
    let bgHeight = document.getElementById("bg").offsetHeight;
    let pointPosition = boxPoint.offsetTop; // top space to 'bg' parent element
    let boxInfoPosition = boxInfo.getBoundingClientRect().top;

    let boxDashedHeight = boxInfoPosition - (pointPosition - (bgHeight - screenHeight) / 2) - 11;

    boxDashed.getElementsByClassName("box-dashed")[0].style.height = boxDashedHeight + 0 + "px";

    //hide previous selected elements
    let prevId = prevSelectedId;
    if (prevId !== "") {
      if (id !== prevId) {
        document.getElementById("box-info-" + prevId).className = "box-info";
        document.getElementById("box-dashed-" + prevId).className = "box-lines";
      }
    }
    setPrevSelectedId(id);
    setIsOpened(true);
  };

  let structuredJSON = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "HydroPro Ltd",
    image: "https://hydropro.bg/HydroPro-color.png",
    "@id": "",
    url: "https://hydropro.bg/",
    telephone: "+359 2/99 280 16",
    address: {
      "@type": "PostalAddress",
      streetAddress: "bul. Tsarigradsko shose 164",
      addressLocality: "Sofia",
      postalCode: "1138",
      addressCountry: "BG",
    },
  };

  const productsListPoints = productsList.map(
    (productsList, index) =>
      productsList.name && (
        <div key={index} id={"box-point-" + index}>
          <div className="box-point" onClick={() => productsToggle(index)}>
            <span className="point"></span>
          </div>
        </div>
      )
  );

  const productsListBoxDashed = productsList.map((productsList, index) => (
    <div key={index} id={"box-dashed-" + index} className="box-lines">
      <div className="box-dashed">
        <img src="/home/dot.svg" alt="home" />
      </div>
      {index === 8 ? (
        <div className="box-dashed box-dashed-2">
          <img src="/home/dot.svg" alt="home" />
        </div>
      ) : null}
    </div>
  ));

  const productsListBoxInfo = productsList.map(
    (productsList, index) =>
      productsList.name && (
        <div key={index} className="box-info" id={"box-info-" + index}>
          <div className="cell-info">
            {productsList.link ? (
              <Link to={productsList.link}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                  <h1 className="">{productsList.name}</h1>
                  <div className="navigate-arrow" />
                </div>
              </Link>
            ) : (
              <h1 className="">{productsList.name}</h1>
            )}

            <p>{productsList.description}</p>
          </div>

          <div className="cell-img">
            {productsList.pdf && productsList.link ? (
              <a href={productsList.link} target="_blank" rel="noopener noreferrer">
                <img src={productsList.img} alt={productsList.name} />
              </a>
            ) : productsList.link ? (
              <Link to={productsList.link}>
                <img src={productsList.img} alt={productsList.name} />
              </Link>
            ) : (
              <img src={productsList.img} alt={productsList.name} />
            )}
          </div>
        </div>
      )
  );
  return (
    <>
      <script className="structured-data-list" type="application/ld+json">
        {JSON.stringify(structuredJSON)}
      </script>
      <div className="bg" id="bg">
        {productsListPoints}
        <div id="box-point-8a">
          <div className="box-point" onClick={() => productsToggle(8)}>
            <span className="point"></span>
          </div>
        </div>
        {productsListBoxDashed}
        <img src="/home/bg@2x.jpg" alt="HYDROPRO" className="desktop bg-img" />
        <img src="/home/bg.jpg" alt="HYDROPRO" className="laptop bg-img" />
      </div>
      {productsListBoxInfo}
    </>
  );
};
