import React from "react";
import "./styles.scss";
import _ from "lodash";
import { Utilities } from "../../utilities";
import { NewsCard } from "../../components";
import { translate } from "../../translations/localization";
import { getArticles, startLoading } from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef } from "react";
import { history } from "../../config/stores";
import { useState } from "react";

const videos = ["https://hydropro.s3.eu-central-1.amazonaws.com/hydropro-website/Hydropro+BG+corporate+video.mp4", "https://hydropro.s3.eu-central-1.amazonaws.com/hydropro-website/Hydropro+BG+%D0%9A%D0%BE%D1%80%D0%BF%D0%BE%D1%80%D0%B0%D1%82%D0%B8%D0%B2%D0%BD%D0%BE+%D0%B2%D0%B8%D0%B4%D0%B5%D0%BE_+Hydropro+BG+corporate+video.mp4"];

export default function AboutUs() {
  const dispatch = useDispatch();
  const newsRef = useRef();
  const videoRef = useRef(null);
  const mobileVideoRef = useRef([]);
  const sliderRef = useRef();

  const newsData = useSelector((state) => state.articles.articles);
  const nextPage = useSelector((state) => state.articles.nextPage);
  const hasNextPage = useSelector((state) => state.articles.hasNextPage);

  const [currentVideo, setCurrentVideo] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [velocity, setVelocity] = useState(0);
  let lastX = 0;
  let lastTime = 0;

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
    }
  };

  const handleVideoEnded = () => setIsPlaying(false);

  const handleVideoChange = (index) => {
    if (index === currentVideo) return;
    setCurrentVideo(index);
    setIsPlaying(false);
    videoRef.current.pause();
    videoRef.current.src = videos[index]; // Update source directly
    videoRef.current.load(); // Force video reload
  };

  const handleScrolling = () => {
    if (newsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = newsRef.current;

      if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1 && hasNextPage && scrollTop !== 0) {
        loadPageOnScroll();
      }
    }
  };

  const delayedScrolling = _.debounce(() => {
    handleScrolling();
  }, 300);

  const loadPageOnScroll = () => {
    dispatch(startLoading());
    dispatch(getArticles({ page: nextPage }));
  };

  useEffect(() => {
    dispatch(getArticles({ page: 1 }));
  }, [dispatch]);

  useEffect(() => {
    const slider = sliderRef.current;
    if (!slider) return;

    slider.addEventListener("scroll", handleScroll);
    return () => slider.removeEventListener("scroll", handleScroll);
  }, []);

  // Touch start event
  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].pageX);
    setScrollLeft(sliderRef.current.scrollLeft);
    lastX = e.touches[0].pageX;
    lastTime = Date.now();
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.touches[0].pageX;
    const delta = x - startX;
    sliderRef.current.scrollLeft = scrollLeft - delta;

    const timeNow = Date.now();
    const deltaTime = timeNow - lastTime;
    setVelocity((x - lastX) / deltaTime);
    lastX = x;
    lastTime = timeNow;
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    let momentum = velocity * 100;

    const momentumScroll = () => {
      if (Math.abs(momentum) < 1) return;
      sliderRef.current.scrollLeft += momentum;
      momentum *= 0.9;
      requestAnimationFrame(momentumScroll);
    };

    momentumScroll();
  };

  const handleScroll = () => {
    if (!sliderRef.current) return;

    const scrollX = sliderRef.current.scrollLeft;
    const videoWidth = sliderRef.current.clientWidth;
    const newIndex = Math.round(scrollX / videoWidth);

    mobileVideoRef.current.map((el) => el.pause());
    setCurrentVideo(newIndex);
  };

  const handleDotClick = (index) => {
    if (!sliderRef.current) return;
    const videoWidth = sliderRef.current.clientWidth;
    sliderRef.current.scrollTo({
      left: videoWidth * index,
      behavior: "smooth",
    });
    mobileVideoRef.current.forEach((video) => video?.pause());
    if (mobileVideoRef.current[index]) mobileVideoRef.current[index].currentTime = 0;
    setCurrentVideo(index);
    setIsPlaying(false);
  };

  return (
    <div className="about-us">
      <div className="container">
        <div className="contact-section-wrapper">
          <div className="about-us-info-wrapper">
            <h1>{translate("headerAboutUs")}</h1>
            {window.innerWidth < 900 ? (
              <>
                <div className="video-slider" ref={sliderRef} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
                  {videos?.map((video, index) => (
                    <div key={index} className="video-slide">
                      <video
                        ref={(el) => (mobileVideoRef.current[index] = el)} // Assign unique ref
                        className="video-element"
                        preload="metadata"
                        playsInline
                        controls
                        poster={index === 0 ? "/imgs/thumbnail1.png" : "/imgs/thumbnail2.png"}
                        onPlay={() => handlePlay(index)}
                        onEnded={() => handleVideoEnded(index)}
                      >
                        <source src={video} type="video/mp4" />
                      </video>
                    </div>
                  ))}
                </div>
                <div className="pagination">
                  {videos.map((_, index) => (
                    <div key={index} className={`dot ${currentVideo === index ? "active" : ""}`} onClick={() => handleDotClick(index)}></div>
                  ))}
                </div>
              </>
            ) : (
              <>
                <div id="video-container" className="video-outer-container">
                  <video ref={videoRef} className="video-container" preload="metadata" playsInline poster={currentVideo === 0 ? "/imgs/thumbnail1.png" : "/imgs/thumbnail2.png"} controls={isPlaying} onPlay={() => setIsPlaying(true)} onEnded={handleVideoEnded}>
                    <source src={videos[currentVideo]} type="video/mp4" />
                  </video>
                  {!isPlaying && (
                    <div className="play-button-icon" onClick={handlePlay}>
                      <div className="play-triangle"></div>
                    </div>
                  )}
                </div>
                <div className="pagination">
                  {videos.map((_, index) => (
                    <div key={index} className={`dot ${currentVideo === index ? "active" : ""}`} onClick={() => handleVideoChange(index)}></div>
                  ))}
                </div>
              </>
            )}

            <p>{translate("hydroproAboutUsInfo")}</p>
          </div>

          <div className="contacts-info-wrapper">
            <h1>{translate("contacts")}</h1>

            <div className="row">
              <img src={require("../../assets/icons/phone-blue.svg")} className="icon" alt="icon phone" />
              <p>+359 2/99 280 16 +359 893 644 135</p>
            </div>
            <div className="row">
              <img src={require("../../assets/icons/mail-blue.svg")} className="icon" alt="icon mail" />
              <p>office@hydropro.bg</p>
            </div>
            <div className="row">
              <img src={require("../../assets/icons/location.svg")} className="icon" alt="icon location" />
              <p>{translate("contactsAddress")}</p>
            </div>

            <div className="row-wrapper">
              <div className="row" style={{ marginLeft: "2px" }}>
                <img src={require("../../assets/icons/LinkedIn-icon.svg")} className="icon" alt="icon linkedIn" />
                <p style={{ paddingTop: "5px" }} onClick={() => window.open("https://www.linkedin.com/company/hydroprobg/")}>
                  HydroproBG
                </p>
              </div>
              <div className="row">
                <img src={require("../../assets/icons/facebook.svg")} className="icon" alt="icon facebook" />
                <p onClick={() => window.open("https://www.facebook.com/profile.php?id=100076252747868")}>Hydropro BG</p>
              </div>
            </div>
          </div>
        </div>
        <div className="news-section-wrapper" onScroll={delayedScrolling} ref={newsRef}>
          <h1>{translate("newsTitle")}</h1>

          <div className="news-content-wrapper">
            {newsData?.map((el) => {
              return <NewsCard keyId={el?._id} photoLink={el?.coverPhoto} title={Utilities.translateLanguage(el?.title)} date={el?.createdAt} onClick={() => history.push(`/news/${el?._id}`)} />;
            })}
          </div>
        </div>
        {window.innerWidth < 900 && (
          <div style={{ height: "5vh", width: "20px", flex: "3", order: "3" }}>
            <p style={{ marginTop: "1px" }}></p>
          </div>
        )}
      </div>
    </div>
  );
}
