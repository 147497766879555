import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { translate } from "../../../translations/localization";
import "../styles.scss";

export const SecondHome = ({ setIsOpened }) => {
  const [productsList] = useState([
    {
      name: translate("secondHome.point1.title"),
      img: "/home2/1.webp",
      description: translate("secondHome.point1.text"),
      //   link: "/product/65afc235e59a501dc54ccd22",
      pdf: false,
    },
    {
      name: translate("secondHome.point2.title"),
      img: "/home2/2.webp",
      pdf: false,
    },
    {
      name: translate("secondHome.point3.title"),
      img: "/home2/3.webp",
      pdf: false,
    },
    {
      name: translate("secondHome.point4.title"),
      img: "/home2/4.webp",
      pdf: false,
    },
    {
      name: translate("secondHome.point5.title"),
      img: "/home2/5.webp",
      pdf: false,
    },
    {
      name: translate("secondHome.point6.title"),
      img: "/home2/6.webp",
      pdf: false,
    },
    {
      name: translate("secondHome.point7.title"),
      img: "/home2/7.webp",
      description: translate("secondHome.point7.text"),
      pdf: false,
    },
    {
      name: translate("secondHome.point8.title"),
      img: "/home2/8.webp",
      description: translate("secondHome.point8.text"),
      pdf: false,
    },
    {
      name: translate("secondHome.point9.title"),
      img: "/home2/9.webp",
      pdf: false,
    },
    {
      name: translate("secondHome.point10.title"),
      img: "/home2/10.webp",
      pdf: false,
    },
    {
      name: translate("secondHome.point11.title"),
      img: "/home2/11.webp",
      pdf: false,
    },
    {
      name: translate("secondHome.point12.title"),
      img: "/home2/12.webp",
      pdf: false,
    },
  ]);
  const [prevSelectedId, setPrevSelectedId] = useState("");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".box-info")) {
        if (prevSelectedId !== "") {
          document.getElementById(`box-dashed-second-${prevSelectedId}`).className = "box-lines";
          document.getElementById(`box-info${prevSelectedId}`).className = "box-info";
          setIsOpened(false);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [prevSelectedId]);

  const productsToggle = (id) => {
    let boxPoint = document.getElementById("box-point-second-" + id);
    let boxInfo = document.getElementById("box-info" + id);
    let boxDashed = document.getElementById("box-dashed-second-" + id);

    boxInfo.className = "box-info box-info-active";
    boxDashed.className = "box-dashed-active";

    let screenHeight = document.body.clientHeight;
    let bgHeight = document.getElementById("bg").offsetHeight;
    let pointPosition = boxPoint.offsetTop; // top space to 'bg' parent element
    let boxInfoPosition = boxInfo.getBoundingClientRect().top;

    let boxDashedHeight = boxInfoPosition - (pointPosition - (bgHeight - screenHeight) / 2) - 11;

    boxDashed.getElementsByClassName("box-dashed")[0].style.height = boxDashedHeight + 0 + "px";

    //hide previous selected elements
    let prevId = prevSelectedId;
    if (prevId !== "") {
      if (id !== prevId) {
        document.getElementById("box-info" + prevId).className = "box-info";
        document.getElementById("box-dashed-second-" + prevId).className = "box-lines";
      }
    }
    setPrevSelectedId(id);
    setIsOpened(true);
  };

  let structuredJSON = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "HydroPro Ltd",
    image: "https://hydropro.bg/HydroPro-color.png",
    "@id": "",
    url: "https://hydropro.bg/",
    telephone: "+359 2/99 280 16",
    address: {
      "@type": "PostalAddress",
      streetAddress: "bul. Tsarigradsko shose 164",
      addressLocality: "Sofia",
      postalCode: "1138",
      addressCountry: "BG",
    },
  };

  const productsListPoints = productsList.map(
    (productsList, index) =>
      productsList.name && (
        <div key={index} id={"box-point-second-" + index}>
          <div className="box-point" onClick={() => productsToggle(index)}>
            <span className="point"></span>
          </div>
        </div>
      )
  );

  const productsListBoxDashed = productsList.map((productsList, index) => (
    <div key={index} id={"box-dashed-second-" + index} className="box-lines">
      <div className="box-dashed">
        <img src="/home/dot.svg" alt="home" />
      </div>
    </div>
  ));

  const productsListBoxInfo = productsList.map(
    (productsList, index) =>
      productsList.name && (
        <div key={index} className="box-info" id={"box-info" + index}>
          <div className="cell-info">
            {productsList.link ? (
              <Link to={productsList.link}>
                <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
                  <h1 className="">{productsList.name}</h1>
                  <div className="navigate-arrow" />
                </div>
              </Link>
            ) : (
              <h1 dangerouslySetInnerHTML={{ __html: productsList.name }}></h1>
            )}
            <p style={{ marginTop: "10px" }} dangerouslySetInnerHTML={{ __html: productsList.description }}></p>
          </div>

          <div className="cell-img">
            {productsList.link ? (
              <Link to={productsList.link}>
                <img src={productsList.img} alt={productsList.name} />
              </Link>
            ) : (
              <img src={productsList.img} alt={productsList.name} />
            )}
          </div>
        </div>
      )
  );
  return (
    <>
      <script className="structured-data-list" type="application/ld+json">
        {JSON.stringify(structuredJSON)}
      </script>
      <div className="bg" id="bg">
        {productsListPoints}
        {productsListBoxDashed}
        <img src="/home/bg-2@2x.png" alt="HYDROPRO" className="desktop bg-img" style={{ height: "100vh" }} />
        <img src="/home/bg-2@2x.png" alt="HYDROPRO" className="laptop bg-img" style={{ height: "100vh" }} />
      </div>
      {productsListBoxInfo}
    </>
  );
};
