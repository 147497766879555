import React from "react";
import { NavMainDesktopNew } from "../NavMain";
import { history } from "../../config/stores";
import { HeaderRightSection, SearchBar } from "../../components";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { setTab } from "../../actions";

export default function HeaderDesktopNew() {
  const dispatch = useDispatch();
  return (
    <header className="header" id="header">
      <div
        className="new-logo"
        onClick={() => {
          dispatch(setTab("home"));
          history.push("/");
        }}
      />
      {/* <img src={require("../../assets/icons/white-hydropro.svg")} className='header-logo-new' onClick={() => history.push("/")} alt="logo" /> */}

      <NavMainDesktopNew />
      <div className="header-right-section-wrapper">
        <SearchBar />
        <HeaderRightSection />
      </div>
      {/* <SpecializedMaterialsBttn /> */}
    </header>
  );
}
