import React from "react";
import { NavLink } from "react-router-dom";
import { translate } from "../../translations/localization.js";
import { setTab } from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import "./stylesNew.scss";

export default function NavMainDesktopNew() {
  const dispatch = useDispatch();
  const location = window.location.pathname;
  const currentTab = useSelector((state) => state.general.headerTab);
  const [selectedTab, setSelectedTab] = useState("home");

  useEffect(() => {
    if (currentTab) {
      setSelectedTab(currentTab);
      dispatch(setTab(currentTab));
    }
  }, []);

  console.log(location);
  return (
    <div className="nav-main-wrap">
      <nav className="nav-main">
        <div className="items-l">
          <NavLink
            className={`item ${selectedTab === "products" && location !== "/" ? "active" : ""}`}
            to="/Products/home"
            onClick={() => {
              setSelectedTab("products");
              dispatch(setTab("products"));
            }}
          >
            <span>{translate("headerProducts")}</span>
          </NavLink>
          <NavLink
            className={`item ${selectedTab === "references" && location !== "/"  ? "active" : ""}`}
            to="/references"
            onClick={() => {
              setSelectedTab("references");
              dispatch(setTab("references"));
            }}
          >
            <span>{translate("headerReferences")}</span>
          </NavLink>
        </div>
        <div className="items-r">
          <NavLink
            className={`item ${selectedTab === "calculators" && location !== "/"  ? "active" : ""}`}
            to="/specialized-materials-list"
            onClick={() => {
              setSelectedTab("calculators");
              dispatch(setTab("calculators"));
            }}
          >
            <span>{translate("headerCalculators")}</span>
          </NavLink>
          <NavLink
            className={`item ${selectedTab === "about" && location !== "/"  ? "active" : ""}`}
            to="/about-us"
            onClick={() => {
              setSelectedTab("about");
              dispatch(setTab("about"));
            }}
          >
            <span>{translate("headerAboutUs")}</span>
          </NavLink>
          {/* <NavLink className={`item ${selectedTab === 'sensors' ? 'active' : ''}`} to="/sensors" target="_blank" 
          // onClick={() => {
          // setSelectedTab('sensors')
          // dispatch(setTab('sensors'))
          // }}
          >
          <span>{translate("headerSensors")}</span>
        </NavLink> */}
        </div>
      </nav>
    </div>
  );
}
