import React from "react";
import { connect } from "react-redux";
import { FirstHome } from "./HomeParts/FirstHome";
import "./styles.scss";
import { SecondHome } from "./HomeParts/SecondHome";
import { useState } from "react";

const HomeDesktop = () => {
  const [position, setPosition] = useState(0);
  const [isOpened, setIsOpened] = useState(false);

  const moveSlider = (direction) => setPosition((prev) => Math.max(0, Math.min(1, prev + direction * 0.5)));

  const handleScrollbarClick = (e) => {
    const scrollbar = e.currentTarget;
    const clickX = e.clientX - scrollbar.getBoundingClientRect().left;
    const scrollbarWidth = scrollbar.offsetWidth;

    // Determine new position based on where the user clicked
    if (clickX < 0 || clickX > scrollbarWidth) return;
    else {
      moveSlider(clickX < scrollbarWidth / 2 ? -1 : 1);
    }
  };

  return (
    <div className="home" id="products-list">
      {position === 0 ? <FirstHome setIsOpened={setIsOpened} /> : <SecondHome setIsOpened={setIsOpened}/>}
      <div className={`scrollbar-screen-container ${isOpened ? "opened" : ""}`}>
        {position > 0 && <div className="arrow" onClick={() => moveSlider(-1)}></div>}
        <div className="scrollbar-line" onClick={handleScrollbarClick}>
          <div className="scrollbar-slider" id="slider" style={{ left: `${position * 100}%` }}></div>
        </div>
        {position === 0 && <div className="arrow next" onClick={() => moveSlider(1)}></div>}
      </div>
    </div>
  );
};

export default connect()(HomeDesktop);
